.skeleton-placeholder {
    background: linear-gradient(90deg, #e8e8e8 0px, #f8f8f8 40px, #dfdddd 80px);
    /* background-size: 350px; */
    border-radius: 3px;
    animation: animation 1.5s infinite;
}

/* @keyframes animation {
    0% {
      background-position: -100px;
    }
    40%,
    100% {
      background-position: 270px;
    }
  } */


  @keyframes animation {

      0% {

          opacity: 1
        }
		50% {

            opacity: 0.4
        }
		100% {
            
            opacity: 1
        }
    }