.swiper-button-next {
    background-color: rgb(68, 68, 68);
    color: white;
    position: absolute;
    right: 0;
    padding: 27px;
    border-radius: 50%;
  }
  
  .swiper-button-next:hover {
    opacity: 100%;
  }
  
  .swiper-button-prev {
    background-color: rgb(68, 68, 68);
    color: white;
    position: absolute;
    left: 0;
    padding: 27px;
    border-radius: 50%;
  }
  
  .swiper-button-prev:hover {
    opacity: 100%;
  }
  
  .swiper {
    border: 1px solid #222f53;
    border-radius: 12px;
  }
  
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    position: absolute;
    bottom: -5px;
  }


  .swiper-button-prev::after{
   font-size: 23px;
  }
  .swiper-button-next::after{
   font-size: 23px;
  }
