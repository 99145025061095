@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0 auto;
  font-family: "Noto Serif Bengali", serif;
}
.swiper-pagination {
  padding-top: 50px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
